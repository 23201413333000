'use client';

// imports
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

// lib files
import { changeStationModalAtom } from '@/lib/atoms/change-station-modal';
import { stationConfirmedAtom } from '@/lib/atoms/station-confirmed';
import { useHasMounted } from '@/lib/hooks';
import { openChangeStationModal } from '@/lib/helpers/open-change-station-modal';

// svgs
import LocationIcon from '/public/svg/location.svg';

// styles
import styles from './ConfirmStationPopup.module.scss';
interface ConfirmStationPopupProps {
  shortCommonName: string;
}
const ConfirmStationPopup = (props: ConfirmStationPopupProps) => {
  const {
    shortCommonName
  } = props;
  const [isChangeStationModalOpen, setChangeStationModalOpen] = useAtom(changeStationModalAtom);
  const [stationConfirmed, setStationConfirmed] = useAtom(stationConfirmedAtom);
  const hasMounted = useHasMounted();

  // we need a static piece of state that doesn't change with stationConfirmed.
  // this prevents a flash of the popup once someone has confirmed,
  // but still lets us toggle stationConfirmed, which is what we need for the animation
  const [shouldDisplay, setShouldDisplay] = useState(!stationConfirmed);

  // Dismiss the popup if the user scrolls or clicks on anything on the page.
  // This includes the popup itself - as you will be clicking the change
  // station button, which opens the change station modal, which hides this anyway
  useEffect(() => {
    const handleDocumentAction = () => {
      setStationConfirmed(true);
      setShouldDisplay(false);
    };
    document.addEventListener('click', handleDocumentAction);
    document.addEventListener('scroll', handleDocumentAction);
    return () => {
      document.removeEventListener('click', handleDocumentAction);
      document.removeEventListener('scroll', handleDocumentAction);
    };
  }, [setStationConfirmed]);
  if (isChangeStationModalOpen || !hasMounted || !shouldDisplay) {
    return null;
  }
  return <div className={styles.confirm_station_popup} hidden={stationConfirmed} data-sentry-component="ConfirmStationPopup" data-sentry-source-file="ConfirmStationPopup.tsx">
      <span className={styles.confirm_station_name}>{shortCommonName} is your local station.</span>

      <button onClick={() => openChangeStationModal(setChangeStationModalOpen)} className={styles.change_station_button}>
        <LocationIcon className={styles.location_icon} data-sentry-element="LocationIcon" data-sentry-source-file="ConfirmStationPopup.tsx" />
        Change
      </button>
    </div>;
};
export default ConfirmStationPopup;