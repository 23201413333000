import { ContentServiceVideoObject } from '@/lib/types/api/content-service';
import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils';
import CompanionState from '@/lib/types/atoms/companionState';
import createStorage from '@/lib/helpers/createStorage';

const storage = createStorage<ContentServiceVideoObject[]>()

let storedMyListVideos

if (typeof window !== "undefined") {
  storedMyListVideos = JSON.parse(window.localStorage.getItem('myListVideos')!) as ContentServiceVideoObject[]
} else {
  storedMyListVideos = undefined
}

export const myListVideosAtom = atomWithStorage<ContentServiceVideoObject[] | undefined>('myListVideos', storedMyListVideos, storage);
export const myListVideosStatusAtom = atom<CompanionState>(CompanionState.NotLoaded)
