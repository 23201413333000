'use client';

// imports
import { useAtom } from 'jotai';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Cookies from 'js-cookie';

// lib files
import { getFavoriteStations, getProfile, getViewingHistory } from '@/lib/profile';
import { ProfileData } from '@/lib/types/api/profile-data';
import { STATION_ID_COOKIE, STATION_COOKIE_AGE_DAYS, USER_ID_COOKIE } from '@/lib/constants';
import { userProfile, userProfileStatus } from '@/lib/atoms/profile';
import { percentageWatchedViewingHistoryAtom } from '@/lib/atoms/viewing-history';
import { ViewingHistoryItem } from '@/lib/types/api/viewing-history';
import CompanionState from '@/lib/types/atoms/companionState';
function fetchProfile(setProfileData: Dispatch<SetStateAction<ProfileData | null>>, setViewingHistory: Dispatch<SetStateAction<ViewingHistoryItem[] | null>>, setProfileStatus: Dispatch<SetStateAction<CompanionState>>) {
  return getProfile().then(profileData => {
    setProfileData(profileData);
    setProfileStatus(CompanionState.IsLoadedWithData);
    let stationChangedShouldReload = false;
    const viewingHistoryPromise = getViewingHistory().then((viewingHistory: ViewingHistoryItem[]) => {
      setViewingHistory(viewingHistory);
    });
    const favoriteStationPromise = getFavoriteStations().then(favoriteStations => {
      const currentStationID = Cookies.get(STATION_ID_COOKIE);
      const stationID = favoriteStations.collections[0].content[0].cid;
      if (currentStationID !== stationID) {
        Cookies.set(STATION_ID_COOKIE, stationID, {
          domain: '.pbs.org',
          path: '/',
          expires: STATION_COOKIE_AGE_DAYS,
          sameSite: 'None',
          secure: true
        });
        stationChangedShouldReload = true;
      }
    });
    Promise.all([viewingHistoryPromise, favoriteStationPromise]).then(() => {
      if (stationChangedShouldReload) {
        window.location.reload();
      }
    }).catch(err => {
      console.error(err);
    });
  }).catch(err => {
    setProfileData(null);
    setProfileStatus(CompanionState.Error);
  });
}
const NetworkProfile = () => {
  const [profile, setProfile] = useAtom(userProfile);
  const [profileStatus, setProfileStatus] = useAtom(userProfileStatus);
  const [viewingHistory, setViewingHistory] = useAtom(percentageWatchedViewingHistoryAtom);
  const [shouldFetchProfile, setShouldFetchProfile] = useState(false);
  const [initialProfileFetched, setInitialProfileFetched] = useState(false);
  useEffect(() => {
    const pid = Cookies.get(USER_ID_COOKIE);
    switch (true) {
      case !initialProfileFetched && !!pid:
        setShouldFetchProfile(true);
        setInitialProfileFetched(true);
        break;
      case !pid:
        setProfileStatus(CompanionState.IsLoadedWithoutData);
        setShouldFetchProfile(false);
        break;
      case profileStatus === CompanionState.Error:
        setShouldFetchProfile(false);
        break;
      case profile?.profile.pid === pid:
        setProfileStatus(CompanionState.IsLoadedWithData);
        setShouldFetchProfile(false);
        break;
      case profileStatus === CompanionState.NotLoaded:
      default:
        setShouldFetchProfile(true);
        break;
    }
  }, [profile, profileStatus, setProfileStatus, setShouldFetchProfile]);

  // polling effect
  useEffect(() => {
    if (profile) {
      const profileRefreshTask = () => fetchProfile(setProfile, setViewingHistory, setProfileStatus);
      const everyMinute = 60 * 1000;
      const intervalId = setInterval(profileRefreshTask, everyMinute);
      return () => clearInterval(intervalId);
    }
  }, [profile, setProfile, setViewingHistory, setProfileStatus]);
  useEffect(() => {
    if (shouldFetchProfile) {
      setProfileStatus(CompanionState.IsLoading);
      fetchProfile(setProfile, setViewingHistory, setProfileStatus);
    } else {
      if (!Cookies.get(USER_ID_COOKIE)) {
        setProfile(null);
      }
    }
  }, [shouldFetchProfile, setProfile, setViewingHistory, setProfileStatus]);
  return <></>;
};
export default NetworkProfile;