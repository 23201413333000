'use client';

import { useSearchParams } from 'next/navigation';
import { COUNTRY_ID_COOKIE } from "@/lib/constants";
import { useHasMounted } from "@/lib/hooks";
import Cookies from "js-cookie";
import { useEffect } from "react";
const isDev = process.env.APP_ENV === undefined;
const fetchCountryId = () => fetch('/api/country/').then(r => r.json()).then(json => json.countryId);
function NetworkCountry() {
  const searchParams = useSearchParams();
  const hasMounted = useHasMounted();
  const searchCountryId = searchParams.get('countryId');
  const cookieCountryId = Cookies.get(COUNTRY_ID_COOKIE);
  useEffect(() => {
    // we're in dev mode, and someone is passing the countryId param to force
    // a country change
    if (isDev && searchCountryId) {
      Cookies.set(COUNTRY_ID_COOKIE, searchCountryId);
    } else if (cookieCountryId) {
      // there's already a country set so exit early
      return;
    } else {
      // fetch the countryId from the server which will use the
      // cloudfront-viewer-country header
      fetchCountryId().then(countryId => Cookies.set(COUNTRY_ID_COOKIE, countryId));
    }
  }, []);
  if (!hasMounted) return null;
  return <></>;
}
export default NetworkCountry;