// imports
import { useAtom } from 'jotai';
import { forwardRef, ForwardedRef } from 'react';
import Link from 'next/link';

// lib files
import { StationData } from '@/lib/types/api/stations-data';
import StationLogo from '../StationLogo/StationLogo';
import Button from '../Button/Button';
import { navigationAtom, NavigationStateEnum } from '@/lib/atoms/navigation';

// components
import ChangeStationButton from '@/components/Localization/ChangeStationModal/ChangeStationButton';
import ConfirmStationPopup from '@/components/Localization/ConfirmStationPopup/ConfirmStationPopup';
import StationButton from './StationButton';
import NoStationNavButton from '../NoStationNavButton/NoStationNavButton';

// svgs
import MyStationIcon from '/public/svg/my-station-white.svg';
import NextIcon from '/public/svg/caret-next.svg';

// styles
import styles from './StationMenu.module.scss';
interface StationMenuProps {
  depIsOpen?: boolean;
  stationData?: StationData;
  isSVP: boolean;
}
const StationMenu = forwardRef(function StationMenu(props: StationMenuProps, ref: ForwardedRef<HTMLDivElement>) {
  const {
    depIsOpen,
    stationData,
    isSVP
  } = props;
  const [navigation, setNavigation] = useAtom(navigationAtom);
  const shortCommonName = stationData?.attributes.short_common_name;
  const donateUrl = stationData?.attributes.donate_url;
  const stationLinks = stationData?.links;
  const isOpen = depIsOpen || navigation === NavigationStateEnum.StationMenuOpen;
  let classNames = `${styles.station_menu}`;
  if (isOpen) {
    classNames += ` ${styles.is_open}`;
  }

  // Don't show the station menu if SVP
  if (isSVP) {
    return null;
  }

  // If the user is not localized, show the "Choose Station" button
  // and do not render mega menus
  if (!stationData) {
    return <NoStationNavButton />;
  }
  return <div className={styles.station_menu_item} ref={ref}>
			<StationButton className={styles.station_button} stationData={stationData} />
			{shortCommonName && <ConfirmStationPopup shortCommonName={shortCommonName} />}
      <div className={classNames}>
				<div className={styles.station_menu_inner}>
					<div className={styles.station_menu_info}>
						<h2 className={styles.station_menu_info_title}>
							PBS is brought to you by
						</h2>

						{stationData && <StationLogo stationData={stationData} className={styles.station_menu_logo} width={250} />}

						<p className={styles.station_menu_description}>
							{shortCommonName} helps your community explore new worlds and ideas through programs that educate, inform and inspire. Your tax-deductible donation helps make it all possible.
						</p>

            {donateUrl && <Link href={donateUrl}>
                <Button style='red' size='responsive'>
                  Donate to {shortCommonName}
                </Button>
              </Link>}

            <ChangeStationButton isSVP={isSVP} className={styles.station_menu_change_station_button} />

						<Link href="/my-station/" className={styles.station_menu_explore_more_link}>
							<MyStationIcon className={styles.my_station_icon} />
							Explore more from your station
						</Link>
					</div>

					<div className={styles.station_menu_more_from_station}>
						<h2>More from {shortCommonName}</h2>

						<ul className={styles.station_menu_links}>
							{stationLinks && stationLinks.map(link => {
              return <li key={link.title}>
										<Link className={styles.station_menu_link} href={link.url}>
											{link.title}
											<NextIcon className={styles.next_icon} />
										</Link>
									</li>;
            })}
						</ul>
					</div>
				</div>
      </div>
    </div>;
});
export default StationMenu;