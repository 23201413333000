// eslint-disable-next-line @typescript-eslint/no-explicit-any
function objectsMatchShallow(obj: any, obj2: any) {
  const keys = obj && Object.keys(obj).sort() || []
  const keys2 = obj2 && Object.keys(obj2).sort() || []

  if(keys.length !== keys2.length) return false
  if(keys.length === 0 && keys2.length === 0) return true

  const keysMatch = keys.every((key: string,i:number) => key === keys2[i])
  if(!keysMatch) return false

  const valuesMatch = keys.every((key:string) => key && (obj[key] === obj2[key]))
  return valuesMatch
}

export {
  objectsMatchShallow
}
