// lib files
import { ORIGIN } from '@/lib/constants';
import { setRedirectCookie } from '@/lib/helpers/utils';
import { handleSignOut } from '@/lib/helpers/handle-sign-out';
import { ProfileData } from '@/lib/types/api/profile-data';

// components
import NavLink from '@/components/Navigation/NavLink';

// svgs
import GearIcon from '/public/svg/gear.svg';
import UserIcon from '/public/svg/user.svg';
import PassportCompass from '/public/svg/compass-rose.svg';

// styles
import styles from './UserMenu.module.scss';
interface UserMenuProps {
  className: string;
  profile: ProfileData;
}
const UserMenu = (props: UserMenuProps) => {
  const {
    className,
    profile
  } = props;
  return <div className={`${className} ${styles.user_menu}`} data-sentry-component="UserMenu" data-sentry-source-file="UserMenu.tsx">
      <div className={styles.user_menu_inner}>
        <a href={`${ORIGIN}/sso/profile-link`} onClick={() => setRedirectCookie()} className={styles.profile_name}>
          <UserIcon className={styles.user_icon} data-sentry-element="UserIcon" data-sentry-source-file="UserMenu.tsx" /> Profile
        </a>

        <NavLink href="/settings" data-sentry-element="NavLink" data-sentry-source-file="UserMenu.tsx">
          <GearIcon className={styles.gear_icon} data-sentry-element="GearIcon" data-sentry-source-file="UserMenu.tsx" /> Site Settings
        </NavLink>

        {profile.personal_data.is_passport && <NavLink href="/explore/passport">
            <PassportCompass /> Best of PBS Passport
          </NavLink>}

        <a href={`${ORIGIN}/sso/logout`} onClick={handleSignOut} className={styles.sign_out}>
          Sign Out
        </a>
      </div>
    </div>;
};
export default UserMenu;