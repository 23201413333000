// imports
import { allGenreLinks } from './nav-data';
import NavLink from './NavLink';

// svgs
import PassportCompass from '/public/svg/compass-rose.svg';

// styles
import styles from './AllGenresMenu.module.scss';
import LinkButton from '../Button/LinkButton';
interface AllGenresMenuProps {
  className?: string;
}
const AllGenresMenu = (props: AllGenresMenuProps) => {
  const {
    className
  } = props;
  let classes = styles.all_genres_menu;
  if (className) {
    classes += ` ${className}`;
  }
  return <div className={classes} data-sentry-component="AllGenresMenu" data-sentry-source-file="AllGenresMenu.tsx">
      <h2>Genres</h2>
      <div className={styles.all_genres_menu__genres_wrapper}>
        <ul className={styles.all_genres_menu__left}>
          {allGenreLinks.genres.map(({
          label,
          href
        }, index) => {
          return <li key={index}>
                <NavLink href={href}>
                  {label}
                </NavLink>
              </li>;
        })}
        </ul>
        <ul className={styles.all_genres_menu__right}>
          {allGenreLinks.featured.map(({
          label,
          href,
          include_passport_icon
        }, index) => {
          return <li key={index}>
                {include_passport_icon && <PassportCompass className={styles.passport_compass} />}
                <NavLink href={href}>
                  {label}
                </NavLink>
              </li>;
        })}
        </ul>
      </div>
      <LinkButton href={"/shows/"} data-sentry-element="LinkButton" data-sentry-source-file="AllGenresMenu.tsx">
        All Shows
      </LinkButton>
    </div>;
};
export default AllGenresMenu;