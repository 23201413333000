'use client';

import { useEffect, useCallback, useState } from 'react';
import { useAtom } from 'jotai';
import { myListShowsAtom, myListShowsStatus } from '@/lib/atoms/myListShows';
import { userProfile, userProfileStatus } from '@/lib/atoms/profile';
import CompanionState from '@/lib/types/atoms/companionState';
import { PersonalFavoriteShowsContent } from '@/lib/types/api/profile-data';
import { objectsMatchShallow } from '@/lib/helpers/equality';
function NetworkMyListShows() {
  const [profile] = useAtom(userProfile);
  const [profileStatus] = useAtom(userProfileStatus);
  const [myListShows, setMyListShows] = useAtom(myListShowsAtom);
  const [status, setStatus] = useAtom(myListShowsStatus);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [hasFetchedOnce, setHasFetchedOnce] = useState(false);
  const isProfileReady = profile && profileStatus === CompanionState.IsLoadedWithData;
  const pid = profile?.profile?.pid || '';
  const fetchMyListShows = useCallback(async () => {
    if (status === CompanionState.IsLoading) return;
    setStatus(CompanionState.IsLoading);
    try {
      const response = await fetch(`/api/profile/my-list-shows/${pid}/`);
      const json = await response.json();
      setStatus(CompanionState.IsLoadedWithData);
      setHasFetchedOnce(true);
      setMyListShows(json.myListShowsData);
    } catch (error) {
      setStatus(CompanionState.Error);
      console.error(error);
    }
  }, [pid, status, setStatus, setMyListShows, setHasFetchedOnce]);

  // check against favoriteShows vs myListShowsData because the favoriteShows
  // updates every 10min when the profile data refetches and there's a chance
  // (albeit very small) that the user removed some shows and the myListShows
  // would become stale
  const updateShowsListFromProfileFavorites = useCallback(() => {
    if (status !== CompanionState.IsLoadedWithData) return;
    const favoriteShows = (profile?.personal_data.favorite_shows.content as PersonalFavoriteShowsContent[]);
    const updatedList = myListShows?.filter(show => {
      return favoriteShows.some(s => s.id === show.slug);
    });
    if (updatedList && myListShows && !objectsMatchShallow(updatedList, myListShows)) {
      setMyListShows(updatedList);
    }
  }, [status, profile, myListShows, setMyListShows]);
  useEffect(() => {
    if (myListShows) {
      setShouldFetch(false);
    } else {
      setShouldFetch(true);
    }
  }, [myListShows]);
  useEffect(() => {
    if (isProfileReady) {
      if (!hasFetchedOnce && shouldFetch) {
        fetchMyListShows();
        setShouldFetch(false);
      } else {
        updateShowsListFromProfileFavorites();
      }
    }
  }, [profile, profileStatus, isProfileReady, hasFetchedOnce, shouldFetch, fetchMyListShows, updateShowsListFromProfileFavorites]);
  return <></>;
}
export default NetworkMyListShows;