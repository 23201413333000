import { atomWithStorage } from 'jotai/utils';

let storedStationConfirmed

if (typeof window !== "undefined") {
  storedStationConfirmed = JSON.parse(window.localStorage.getItem('station-confirmed')!);

  // Defaulting to not confirmed
  if (!storedStationConfirmed) {
    window.localStorage.setItem('station-confirmed', JSON.stringify(false));
    storedStationConfirmed = false
  }
} else {
  storedStationConfirmed = false
}

export const stationConfirmedAtom = atomWithStorage<boolean>('station-confirmed', storedStationConfirmed);
