import { useAtom } from 'jotai';
import Button from '@/components/Button/Button';
import { signinModalAtom, SignInModalStateEnum } from '@/lib/atoms/signin-modal';
interface SignInButtonProps {
  className?: string;
  size?: 'responsive' | 'min' | 'max';
  children?: React.ReactNode;
}
;
const SignInButton = (props: SignInButtonProps) => {
  const {
    className,
    size,
    children
  } = props;
  const [isSigninModalOpen, setSigninModalOpen] = useAtom(signinModalAtom);
  return <Button style="white_ghost" onClick={() => setSigninModalOpen(SignInModalStateEnum.True)} className={className} size={size} data-sentry-element="Button" data-sentry-component="SignInButton" data-sentry-source-file="SignInButton.tsx">
      {children || 'Sign In'}
    </Button>;
};
export default SignInButton;