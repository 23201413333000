// imports
import Link from 'next/link';
import { StationData } from "@/lib/types/api/stations-data";
import Image from 'next/image';
import ITSImage from '@/components/ITSImage/ITSImage';
import DonateIcon from '/public/svg/donate-heart.svg';

// this ?url query param is so that we can use this SVG in the Image component
// and everyone's happy. This is similar to the how the footer illustration works.
// @ts-ignore
import DonateIllustration from '/public/svg/woman-with-plant.svg?url';
import LinkButton from '@/components/Button/LinkButton';

// styles
import styles from './DonateMenuItem.module.scss';
interface DonateMenuItemProps {
  stationData: StationData;
  className: string;
}
const DonateMenuItem = (props: DonateMenuItemProps) => {
  const {
    stationData,
    className
  } = props;
  const {
    attributes
  } = stationData;
  const {
    donate_url,
    short_common_name,
    images
  } = attributes;
  const stationLogo = images.find(image => image.profile === 'white-cobranded-logo');
  const stationLogoSrc = stationLogo?.url;
  return <li className={`${className} ${styles.donate_menu_item}`} data-sentry-component="DonateMenuItem" data-sentry-source-file="DonateMenuItem.tsx">
      <Link href={donate_url} target="_blank" rel="noopener noreferrer" className={styles.donate_link} data-sentry-element="Link" data-sentry-source-file="DonateMenuItem.tsx">
        <DonateIcon className={styles.donate_icon} data-sentry-element="DonateIcon" data-sentry-source-file="DonateMenuItem.tsx" />
        Donate
      </Link>

      <div className={styles.donate_menu}>
        <div className={styles.donate_menu_inner}>

          {stationLogoSrc && <ITSImage src={stationLogoSrc} alt={short_common_name} className={styles.donate_station_logo} width={165} />}

          <p className={styles.donate_menu_intro}>
          Support your local PBS station in our mission to inspire, enrich, and educate.
          </p>

          <LinkButton href={donate_url} style="red" target="_blank" size="max" data-sentry-element="LinkButton" data-sentry-source-file="DonateMenuItem.tsx">
          Donate to {short_common_name}
          </LinkButton>

          <Image src={DonateIllustration} alt="" className={styles.donate_illustration} aria-hidden="true" data-sentry-element="Image" data-sentry-source-file="DonateMenuItem.tsx" />
        </div>
      </div>
    </li>;
};
export default DonateMenuItem;